// Internal documentation: https://accessukltd.sharepoint.com/:w:/t/NFPMST-WebsitesandGAG-Inhousedesignerschat/EWtjvoUoKYVMnvSwGkWDM2YBFlshOT3tJCWEksdxZ5TwGA?e=4t2Etf
// External documentation: https://accessukltd.sharepoint.com/:w:/t/NFPMST-WebsitesandGAG-Inhousedesignerschat/EbAetoMLS11OukXWtHcX_SEBPqCgjAZkYEttLljHOdQBhg?e=7gqO8C

// Are we on the prayer timetable page? (/prayer-times)
var prayerTimesPage = $("body").hasClass("prayer-times");

// Set up some month names to use
var monthNames = new Array(
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
);

// Get today's date in a format we can lookup on
var dt = new Date();
var currentDay = dt.getDate();
var currentMonth = dt.getMonth() + 1;
var currentYear = dt.getFullYear();

// Print the nicely formatted current date on the page
$('.date-wrapper .gregorian').html(currentDay + ' ' + monthNames[currentMonth - 1] + ' ' + currentYear);

// This is the object (with child array) where we are going to store our prayer data
var tableContent = {
  data: [],
};

// This is an array we use for storing our dropdown values
var selectValues = [];

// Clear any existing dropdown options
$("select.prayer-times__month-selector option").remove();

// Loop over each row in the footer snippet table
$(".footerBox.Prayerdata tbody tr").each(function () {
  var obj = {}; // We're going to populate this object for each row
  var $this = $(this);

  // We're getting each cell value based on its nth-child value
  // In other words, we ALWAYS expect "gregoriandate" to be in cell 1
  // There's no strict correlation with the table header cell
  obj["gregoriandate"] = $this.find("td:nth-child(1)").text();
  obj["islamicday"] = $this.find("td:nth-child(2)").text();
  obj["islamicmonth"] = $this.find("td:nth-child(3)").text();
  obj["islamicyear"] = $this.find("td:nth-child(4)").text();
  obj["sunrise"] = $this.find("td:nth-child(5)").text();
  obj["fajrbegins"] = $this.find("td:nth-child(6)").text();
  obj["fajrjamāah"] = $this.find("td:nth-child(7)").text();
  obj["zuhrbegins"] = $this.find("td:nth-child(8)").text();
  obj["zuhrjamāah"] = $this.find("td:nth-child(9)").text();
  obj["asrmithl1"] = $this.find("td:nth-child(10)").text();
  obj["asrmithl2"] = $this.find("td:nth-child(11)").text();
  obj["asrjamāah"] = $this.find("td:nth-child(12)").text();
  obj["maghribbegins"] = $this.find("td:nth-child(13)").text();
  obj["maghribjamāah"] = $this.find("td:nth-child(14)").text();
  obj["ishābegins"] = $this.find("td:nth-child(15)").text();
  obj["ishājamāah"] = $this.find("td:nth-child(16)").text();

  // Put our row data into our master data store
  tableContent.data.push(obj);
});

// Now that we have our data, let's get it on the page in the correct places
// We're looping over each "row" again to process
for (var i = 0; i < tableContent.data.length; i++) {
  // Our gregorian date is just a string in the footer snippet
  // We need to convert it to a date, but because it's going to be formatted as dd/mm/yyyy,
  // we need to flip some things round so its in yyyy, mm, dd for JavaScript's new Date() function
  var deconstructedDateString = tableContent.data[i].gregoriandate.split("/");
  var gregDate = new Date(
    deconstructedDateString[2], // year
    deconstructedDateString[1] - 1, // month (zero based)
    deconstructedDateString[0] // day
  );

  // Now we can safely get some date variables to use
  var gregDay = gregDate.getDate();
  var gregMonth = gregDate.getMonth() + 1;
  var gregYear = gregDate.getFullYear();

  // Check whether the current row is today
  var today = "";
  if (
    gregDay == currentDay &&
    gregMonth == currentMonth &&
    gregYear == currentYear
  ) {
    today = "today";
  }

  // If it's today, then put the relevant data into the header snippet
  if (today) {
    // Print the Islamic date at the top
    $(".date-wrapper .hijri").text(
      tableContent.data[i].islamicday +
        " " +
        tableContent.data[i].islamicmonth +
        " " +
        tableContent.data[i].islamicyear
    );

    // The begins row
    $(".salah-time-row.time-start .fajr").text(tableContent.data[i].fajrbegins);
    $(".salah-time-row.time-start .zuhr").text(tableContent.data[i].zuhrbegins);
    $(".salah-time-row.time-start .asr").text(tableContent.data[i].asrmithl1);
    $(".salah-time-row.time-start .maghrib").text(
      tableContent.data[i].maghribbegins
    );
    $(".salah-time-row.time-start .isha").text(tableContent.data[i].ishābegins);

    // The Jama'ah row
    $(".salah-time-row.time-end .fajr").text(tableContent.data[i].fajrjamāah);
    $(".salah-time-row.time-end .zuhr").text(tableContent.data[i].zuhrjamāah);
    $(".salah-time-row.time-end .asr").text(tableContent.data[i].asrjamāah);
    $(".salah-time-row.time-end .maghrib").text(
      tableContent.data[i].maghribjamāah
    );
    $(".salah-time-row.time-end .isha").text(tableContent.data[i].ishājamāah);
  }

  // Otherwise, let's crunch some data for the prayer times page!
  if (prayerTimesPage) {
    // If the row is in the past, continue to next row loop iteration
    // because we don't care about past dates
    if (gregMonth < currentMonth && gregYear == currentYear) {
      continue;
    }

    // Check whether this row's month and year has been populated yet in the dropdown
    if (!selectValues.includes(monthNames[gregMonth - 1] + " " + gregYear)) {
      selectValues.push(monthNames[gregMonth - 1] + " " + gregYear);
      // And if not, add in an <option> element for that month year combo
      $("select.prayer-times__month-selector").append(
        '<option value="' +
          gregMonth +
          "-" +
          gregYear +
          '">' +
          monthNames[gregMonth - 1] +
          " " +
          gregYear +
          "</option>"
      );
    }

    // Populate the HTML table in the post content
    function populatePrayerRows(item) {
      // Row template
      var rowTemplate =
        "<tr class='" +
        today +
        " " +
        gregMonth +
        "-" +
        gregYear +
        "'><td>" +
        gregDay +
        "</td><td>" +
        item["fajrbegins"] +
        "</td><td>" +
        item["fajrjamāah"] +
        "</td><td>" +
        item["sunrise"] +
        "</td><td>" +
        item["zuhrbegins"] +
        "</td><td>" +
        item["zuhrjamāah"] +
        "</td><td>" +
        item["asrmithl1"] +
        "</td><td>" +
        item["asrmithl2"] +
        "</td><td>" +
        item["asrjamāah"] +
        "</td><td>" +
        item["maghribbegins"] +
        "</td><td>" +
        item["maghribjamāah"] +
        "</td><td>" +
        item["ishābegins"] +
        "</td><td>" +
        item["ishājamāah"] +
        "</td></tr>";

      $("table.prayer-times tbody").append(rowTemplate);
    }

    // Call the above function
    populatePrayerRows(tableContent.data[i]);
  }
}

// This function handles the changing of the month-year select dropdown
function setMonth(month, year) {
  // Set the text in the first cell of the header row
  $("th.currentMonth").text(monthNames[month - 1]);

  // Select the appropriate dropdown
  $(
    'select.prayer-times__month-selector option[value="' +
      month +
      "-" +
      year +
      '"]'
  ).prop("selected", true);

  // Hide all data rows that don't match
  $("table.prayer-times tbody tr:not(" + month + "-" + year + ")").hide();
  $("table.prayer-times tbody tr." + month + "-" + year).show();
}

if (prayerTimesPage) {
  // By default, set the dropdown to the current month-year
  setMonth(currentMonth, currentYear);

  $("select.prayer-times__month-selector").change(function () {
    // Split the month-year values from the option's value attribute
    var month = $(this).val().split("-")[0];
    var year = $(this).val().split("-")[1];

    // So that we can parse them to the setMonth func as individual parameters
    setMonth(month, year);
  });
}
